import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FolderState, FolderStore } from './folder.store';
import { Observable } from 'rxjs';
import { FolderDetails } from '@core/types';

@Injectable()
export class FolderQuery extends Query<FolderState> {

    constructor(protected store: FolderStore) {
        super(store);
    }

    public getId(): string {
        return this.getValue().id;
    }

    public isCreated(): boolean {
        return !!this.getValue().id;
    }

    public getDetails(): FolderDetails {
        return this.getValue();
    }

    public getDetails$(): Observable<FolderDetails> {
        return this.select((state) => state);
    }

    public isDetailsExist(): boolean {
        return !!this.getDetails().projectName;
    }

    public isFolderReadyToFileUpload$(): Observable<boolean> {
        return this.store.isFolderReadyToFileUpload();
    }
}
