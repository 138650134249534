import { IDownloadReport } from '@core/types';

export const DOWNLOAD_REPORTS: IDownloadReport[] = [
    {
        id: 0,
        title: 'Key Issues Report',
        icon: 'key-issues-icon',
        key: 'key-issues',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 1,
        title: 'Scoping Report',
        icon: 'key-issues-icon',
        key: 'scoping',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 3,
        title: 'Portfolio Report',
        icon: 'excel-icon',
        key: 'full',
        format: 'xlsx',
        isDownloaded: false,
    },
    {
        id: 5,
        title: 'Summary Report',
        icon: 'summary-icon',
        key: 'summary',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 7,
        title: 'Split Detailed Reports',
        icon: 'individual-icon',
        key: 'full',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 9,
        title: 'Detailed Report',
        icon: 'full-report-icon',
        key: 'full',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 11,
        title: 'Scottish Key Issues Report',
        icon: 'key-issues-icon',
        key: 'scottish-key-issues',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 13,
        title: 'Scottish Portfolio Report',
        icon: 'excel-icon',
        key: 'scottish-full',
        format: 'xlsx',
        isDownloaded: false,
    },
    {
        id: 15,
        title: 'Scottish Summary Report',
        icon: 'summary-icon',
        key: 'scottish-summary',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 17,
        title: 'Split Scottish Detailed Reports',
        icon: 'individual-icon',
        key: 'scottish-full',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 19,
        title: 'Scottish Detailed Report',
        icon: 'full-report-icon',
        key: 'scottish-full',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 21,
        title: 'Analysed Title Sheets',
        icon: 'individual-icon',
        key: 'scottish-title-sheets',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 23,
        title: 'Split Certificates of Title',
        icon: 'individual-icon',
        key: 'cot',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 25,
        title: 'Certificate of Title',
        icon: 'cot-icon',
        key: 'cot-combined',
        format: 'docx',
        isDownloaded: false,
    },
    {
        id: 27,
        title: 'Official Copies',
        icon: 'individual-icon',
        key: 'official-copies',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 29,
        title: 'Scottish Certificates of Title',
        icon: 'individual-icon',
        key: 'scottish-cot',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 31,
        title: 'Scottish Certificate of Title (Long Form)',
        icon: 'full-report-icon',
        key: 'scottish-cot-long',
        format: 'zip',
        isDownloaded: false,
    },
    {
        id: 32,
        title: 'NHF Certificate of Title',
        icon: 'full-report-icon',
        key: 'nhf-cot',
        format: 'docx',
        isDownloaded: false,
    },
];
