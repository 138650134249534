/* eslint-disable indent*/
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@datorama/akita-ng-effects';
import { CompileApi } from '../../api';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CompileStore } from './compile.store';
import {
    compilingFailed,
    compilingFinishChecked,
    compilingPartialSuccess,
    compilingStarted,
    compilingSuccess,
    reportDownloaded,
    somethingWentWrong,
} from './compile.actions';
import { MatDialog } from '@angular/material/dialog';
import { ReportGenerationSuccessComponent } from '../../components/report-generation/dialogs/report-generation-success/report-generation-success.component';
import {
    ReportGenerationPartialSuccessComponent,
} from '../../components/report-generation/dialogs/report-generation-partial-success/report-generation-partial-success.component';
import { ReportGenerationFailedComponent } from '../../components/report-generation/dialogs/report-generation-failed/report-generation-failed.component';
import { FolderQuery } from '../folders';
import { CompileService } from './compile.service';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { FileService } from '@services';
import { NEVER } from 'rxjs';
import { FILE_NOT_FOUND, SOMETHING_GONE_WRONG } from '@constants';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CompileEffects {
    public readonly folderIdPlaceholder = '{folder_id}';

    @Effect()
    public compilingStarted$ = this.actions$.pipe(
            ofType(compilingStarted),
            switchMap((action) =>
                this.folderQuery.isDetailsExist()
                    ? this.compileService.startCompile(action.folderId)
                    : this.compileService.getFolderDetailsAndStartCompile(action.folderId),
            ),
        );

    @Effect()
    public compilingSuccess$ = this.actions$.pipe(
            ofType(compilingSuccess),
            tap((action) => {
                this.compileService.completeLoadingDialog()
                    .subscribe(() => {
                        const dialog = this.dialog.open(ReportGenerationSuccessComponent, {
                            panelClass: 'report-dialog',
                            width: '400px',
                            data: { ...action },
                        });

                        dialog.afterClosed().subscribe((reportLink) => {
                            if (!!reportLink) {
                                this.actions$.dispatch(reportDownloaded({ reportLink }));
                            }
                        });

                        this.actions$.dispatch(reportDownloaded({ reportLink: action.linkToReport }));
                    });
            }),
        );

    @Effect()
    public compilingPartialSuccess$ = this.actions$.pipe(
            ofType(compilingPartialSuccess),
            tap((action) =>
                this.compileService.completeLoadingDialog()
                    .subscribe(() => {
                        const dialog = this.dialog.open(ReportGenerationPartialSuccessComponent, {
                            panelClass: 'report-dialog',
                            width: '586px',
                            data: { ...action },
                        });

                        dialog.afterClosed().subscribe((reportLink) => {
                            if (!!reportLink) {
                                this.actions$.dispatch(reportDownloaded({ reportLink }));
                            }
                        });

                        this.actions$.dispatch(reportDownloaded({ reportLink: action.linkToReport }));
                    }),
            ),
        );

    @Effect()
    public compilingFailed$ = this.actions$.pipe(
            ofType(compilingFailed),
            tap((action) =>
                this.compileService.completeLoadingDialog()
                    .subscribe(() => {
                        this.dialog.open(ReportGenerationFailedComponent, {
                            panelClass: 'report-dialog',
                            width: '400px',
                            data: { ...action },
                        });
                    }),
            ),
        );

    @Effect()
    public somethingWentWrong$ = this.actions$.pipe(
            ofType(somethingWentWrong),
            tap(({ title, message }) => {
                const defaultTitle = SOMETHING_GONE_WRONG.title;
                const defaultMessage = SOMETHING_GONE_WRONG.message;

                this.dialog.open(AlertDialogComponent, {
                    panelClass: 'report-dialog',
                    width: '400px',
                    data: {
                        title: title || defaultTitle,
                        message: message || defaultMessage,
                    },
                });
            }),
        );

    @Effect()
    public compilingFinishChecked$ = this.actions$.pipe(
            ofType(compilingFinishChecked),
            tap(() => {
                const isCompilationInProgress = this.compileStore.isCompilationInProgress();
                const folderId = this.folderQuery.getId();

                if (isCompilationInProgress) {
                    this.compileService.startCompile(folderId).subscribe();
                }
            }),
        );

    @Effect()
    public reportDownloaded$ = this.actions$.pipe(
            ofType(reportDownloaded),
            switchMap((action) => {
                const folderId = action.folderId || this.folderQuery.getId();
                const reportLink = action.reportLink.replace(this.folderIdPlaceholder, folderId);

                return this.apiService.loadReport(reportLink)
                    .pipe(
                        catchError((error) => {
                            if (error instanceof HttpErrorResponse) {
                                if (error.status === 404) {
                                    this.actions$.dispatch(somethingWentWrong(FILE_NOT_FOUND));
                                    return NEVER;
                                }
                            }

                            this.actions$.dispatch(somethingWentWrong({}));

                            return NEVER;
                        }),
                    );
            }),
            tap((response) => this.fileService.download(response)),
        );

    constructor(
        private readonly actions$: Actions,
        private readonly compileStore: CompileStore,
        private readonly apiService: CompileApi,
        private readonly compileService: CompileService,
        private readonly dialog: MatDialog,
        private readonly folderQuery: FolderQuery,
        private readonly fileService: FileService,
    ) {
    }
}
