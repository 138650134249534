import { createAction, props } from '@datorama/akita-ng-effects';
import { ICollectionPageEvent, ICollectionTableUI } from '@core/types';
import { BookmarksList } from '../../types/bookmarks-list.type';

const ACTION_NAME_TAG = '[Leases Bookmark]';

export const bookmarksLoaded = createAction(
    `${ACTION_NAME_TAG} Bookmarks list loaded`,
    props<{ params: ICollectionPageEvent }>(),
);

export const bookmarksSearched = createAction(
    `${ACTION_NAME_TAG} Bookmarks searched`,
    props<{ params: ICollectionTableUI }>(),
);

export const bookmarksSorted = createAction(
    `${ACTION_NAME_TAG} Bookmark sorted`,
    props<{ params: ICollectionPageEvent }>(),
);

export const bookmarksLoadingSuccess = createAction(
    `${ACTION_NAME_TAG} Bookmarks loading success`,
    props<{ bookmarksList: BookmarksList }>(),
);

export const bookmarksLoadingFailed = createAction(
    `${ACTION_NAME_TAG} Bookmarks loading failed`,
    props<{ error: string }>(),
);
