<button
    type="button"
    class="dialog__close"
    mat-dialog-close
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<h4 mat-dialog-title>HM Land Registry</h4>
<avl-land-registry-search
    class="land-registry-search"
    [isLoading]="loading$ | async"
    [searchType]="initialSearchType"
    (searchSubmitted)="onSearchChanged($event)"
    (searchTypeChanged)="onSearchTypeChanged($event)"
></avl-land-registry-search>
<div
    *ngIf="this.isMapSearch(searchType)"
    @fadeRowAnimation
    class="land-registry-dialog__map-results"
>
    <avl-land-registry-map-search></avl-land-registry-map-search>
</div>

<div
    *ngIf="!this.isMapSearch(searchType) && ((loading$ | async) || !isResultsListEmpty)"
    class="land-registry-dialog__results"
    @fadeRowAnimation
>
    <div class="land-registry-dialog__results-container">
        <div class="land-registry-dialog__results-btn-container">
            <button
                matRipple
                class="land-registry-dialog__download-button"
                [disabled]="!(searchResultsUrl$ | async)?.length"
                (click)="downloadSearchResults()"
            >
                <span>Results</span>
                <mat-icon
                    class="land-registry-dialog__download-icon"
                    svgIcon="download"
                ></mat-icon>
            </button>
        </div>
        <div
            *ngIf="errorTitles$ | async as errorTitles"
            class="land-registry-dialog__error-container"
        >
            <avl-multiple-error-alert
                [titleNumbers]="errorTitles"
                (documentRemoved)="onTitleErrorRemove($event)"
            ></avl-multiple-error-alert>
        </div>
        <avl-land-registry-results-table
            [titles]="resultTitles$ | async"
            [loading]="loading$ | async"
            [selectedTitles]="titlesFromBasket$ | async"
            (selectionChanged)="onSelectedTitles($event)"
        ></avl-land-registry-results-table>
    </div>
</div>

<avl-purchase-titles-button
    @fadeRowAnimation
    *ngIf="this.isMapSearch(searchType) || (loading$ | async) || !isResultsListEmpty"
    [counter]="titlesCount$ | async"
    [price]="totalPrice$ | async"
    [purchaseAction]="purchaseLoading$ | async"
    (titlesPurchased)="onTitlesPurchased()"
></avl-purchase-titles-button>
