import { Injectable } from '@angular/core';
import { CompileStore } from './compile.store';
import { ReportLoadingProgressComponent } from '../../components/report-generation/dialogs/report-loading-progress/report-loading-progress.component';
import { BehaviorSubject, NEVER, Observable, Subject, timer } from 'rxjs';
import { DocumentQuery } from '../documents';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { compilingFailed, compilingPartialSuccess, compilingSuccess, somethingWentWrong } from './compile.actions';
import { Actions } from '@datorama/akita-ng-effects';
import { CompilingStatus } from '../../types/compiling-status.type';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { CompileApi } from '../../api';
import { folderDetailsUpdated, FolderService } from '../folders';
import { CompileQuery } from './compile.query';
import { InformationSnackbarComponent } from '../../components/report-generation/information-snackbar/information-snackbar.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Action } from '@datorama/akita-ng-effects/lib/types';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CompileService {
    private readonly pollingInterValMs = 3000;
    private readonly progressDialogCompletedAndVisibleMs = 1200;
    private readonly linkPlaceholderKey = '$report';

    private readonly progressTitle$ = new BehaviorSubject<string>('Loading...');
    private readonly progressMessages$ = new BehaviorSubject<string>('');
    private readonly progressPercent$ = new BehaviorSubject<number>(0);
    private readonly progressAcceleratorUnsubscribe$ = new Subject<void>();

    private loadingDialog?: MatDialogRef<ReportLoadingProgressComponent>;
    private emailSnackbar?: MatSnackBarRef<InformationSnackbarComponent>;

    constructor(
        private readonly actions: Actions,
        private readonly compileStore: CompileStore,
        private readonly compileQuery: CompileQuery,
        private readonly documentQuery: DocumentQuery,
        private readonly compileApi: CompileApi,
        private readonly folderService: FolderService,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
    ) {
    }

    public startCompile(folderId: string): Observable<string> {
        this.compileStore.setLoading(true);

        return this.compileApi.startCompile(folderId)
            .pipe(
                tap((processId) => this.compileStore.update({ processId, isEmailWasShown: false })),
                tap(() =>
                    this.getCompilingStatusPolling(folderId)
                        .pipe(
                            finalize(() => this.compileStore.setLoading(false)),
                        )
                        .subscribe((status) => this.finishCompiling(status)),
                ),
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.httpErrorHandler(error);
                    } else {
                        this.actions.dispatch(somethingWentWrong({}));
                    }

                    return NEVER;
                }),
            );
    }

    public getFolderDetailsAndStartCompile(folderId: string): Observable<string> {
        return this.folderService.getFolderDetails()
            .pipe(
                tap((folderDetails) => this.actions.dispatch(folderDetailsUpdated({ folderId, folderDetails }))),
                switchMap(() => this.startCompile(folderId)),
            );
    }

    public showLoadingDialog(): void {
        const title = this.progressTitle$.asObservable();
        const message = this.progressMessages$.asObservable();
        const percentage = this.progressPercent$.asObservable();

        this.loadingDialog = this.dialog.open(ReportLoadingProgressComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            disableClose: true,
            data: { title, message, percentage },
        });
    }

    public completeLoadingDialog(): Observable<void> {
        return new Observable((subscriber) => {
            const isDialogClosed = this.loadingDialog?.getState() !== MatDialogState.OPEN;

            if (isDialogClosed) {
                subscriber.next();
                subscriber.complete();
            } else {
                this.loadingDialog?.componentInstance?.complete();

                timer(this.progressDialogCompletedAndVisibleMs)
                    .pipe(
                        tap(() => this.loadingDialog?.close()),
                        tap(() => this.emailSnackbar?.dismiss()),
                    )
                    .subscribe(() => {
                        subscriber.next();
                        subscriber.complete();
                    });
            }
        });
    }

    private getCompilingStatusPolling(folderId: string): Observable<CompilingStatus> {
        return timer(0, this.pollingInterValMs)
            .pipe(
                map(() => this.compileStore.getValue().processId),
                switchMap((processId) => this.compileApi.getStatus(folderId, processId)),
                tap((status) => this.compileStore.update({ ...status })),
                tap((status) => this.updateLoadingDialog(status)),
                tap((status) => this.handleEmailToast(status.isEmailToastShouldBeShown && !status.isFinished)),
                filter((status) => status.isFinished),
                take(1),
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.httpErrorHandler(error);
                    } else {
                        this.actions.dispatch(somethingWentWrong({}));
                    }

                    return NEVER;
                }),
            );
    }

    private updateLoadingDialog(status: CompilingStatus): void {
        if (!status.isFinished) {
            const isDialogClosed = this.loadingDialog?.getState() !== MatDialogState.OPEN;
            if (isDialogClosed) {
                this.progressPercent$.next(0);
                this.showLoadingDialog();
            }

            this.progressTitle$.next(status.title);
            this.progressMessages$.next(status.message[0][0]);
            this.startCounterAccelerator(this.progressPercent$.getValue(), status.progress, this.pollingInterValMs);
        }
    }

    private startCounterAccelerator(start: number, goal: number, time: number): void {
        if (start >= goal) {
            return;
        }

        this.progressAcceleratorUnsubscribe$.next();

        const distance = goal - start;
        const speed = time / distance;

        timer(0, speed)
            .pipe(
                map((value) => value + start),
                takeWhile((value) => value <= goal),
                takeUntil(this.progressAcceleratorUnsubscribe$),
            )
            .subscribe((value) => this.progressPercent$.next(value));
    }

    private handleEmailToast(isEmailToastShouldBeShown: boolean): void {
        const isEmailWasShown = this.compileQuery.isEmailWasShown();

        if (isEmailToastShouldBeShown && !isEmailWasShown) {
            this.showEmailSnackbar();
            this.compileStore.update({ isEmailWasShown: true });
        }
    }

    private finishCompiling(status: CompilingStatus): void {
        if (!status.isFinished) {
            return;
        }

        const title = status.title;
        const linkToReport = status.linkToReport;
        const linkMessage = status.linkMessage;
        const firstMessage = status.message[0][0];
        const secondMessage = status.message[0][1];
        const linkPlaceholder = this.linkPlaceholderKey;
        let action: Action = compilingFailed({ title, message: firstMessage });

        if (status.isSuccess) {
            action = compilingSuccess({
                title,
                message: firstMessage,
                linkMessage,
                linkToReport,
                linkPlaceholder,
            });
        } else if (status.isPartialSuccess) {
            action = compilingPartialSuccess({
                title,
                firstMessage,
                secondMessage,
                linkMessage,
                linkToReport,
                linkPlaceholder,
            });
        }

        this.actions.dispatch(action);
    }

    private httpErrorHandler(httpError: HttpErrorResponse): void {
        const errorTitle = httpError.error.error;
        const errorMessage = httpError.error.message;

        this.actions.dispatch(somethingWentWrong({ title: errorTitle, message: errorMessage }));
    }

    private showEmailSnackbar(): void {
        this.emailSnackbar = this.snackBar.openFromComponent(InformationSnackbarComponent, {
            data: 'Our AI is analysing a lot of leases right now, so we’ll need a bit more time than '
                + 'normal. We’ll send you an email when it’s ready to download! You can close your browser '
                + 'if you want, or keep it open if you’re happy to wait 🥳',
            panelClass: ['snackbar-view'],
        });
    }
}
