import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { IFilesForDownload } from 'app/titles/types';

export type PurchasedSelectedFilesState = EntityState<IFilesForDownload, string>;

@Injectable()
@StoreConfig({ name: 'purchased-selected-files', idKey: 'documentId', resettable: true })
export class PurchasedSelectedFilesStore extends EntityStore<PurchasedSelectedFilesState> {
    constructor() {
        super();
    }
}
