import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDownloadReport } from '@core/types';


@Component({
    selector: 'avl-download-report-button',
    templateUrl: './download-report-button.component.html',
    styleUrls: ['./download-report-button.component.scss'],
})
export class DownloadReportButtonComponent {
    @Input()
    public value: IDownloadReport;

    @Input()
    public disableButton: boolean;

    @Output()
    public reportDownloaded = new EventEmitter<IDownloadReport>();

    public reportDownload(value: IDownloadReport): void {
        this.reportDownloaded.emit(value);
    }
}
