import { Component, EventEmitter, Output } from '@angular/core';
import { AvailToolKey } from '@enums';

@Component({
    selector: 'avl-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    public readonly tools = AvailToolKey;

    @Output()
    public createNewFolder = new EventEmitter();

    @Output()
    public closeSideNav = new EventEmitter();

    public onCreateNewFolder(): void {
        this.createNewFolder.emit();
    }

    public onClose(): void {
        this.closeSideNav.emit();
    }
}
