/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@datorama/akita-ng-effects';
import { FolderStore } from './folder.store';
import { FolderApi } from '../../api';
import { folderCreateSuccess, folderDetailsUpdated, newFolderCreated, newFolderInitiated } from './folder.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { documentStateReset } from '../documents';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../components/notification-center/services/notification.service';
import { UNABLE_COMMUNICATE_WITH_SERVER } from '@constants';
import { FolderService } from './folder.service';

@Injectable()
export class FolderEffects {
    @Effect()
    public initiatedFolder$ = this.actions$.pipe(
            ofType(newFolderInitiated),
            tap(() => {
                this.notificationCenter.reset();
                this.actions$.dispatch(documentStateReset());
                this.folderStore.update({ id: '', projectName: '', matterNumber: '' });
                this.folderStore.folderReset();

                this.router.navigate(['/lease/upload'], {
                    relativeTo: this.activatedRoute,
                });
            }),
        );

    @Effect({ dispatch: true })
    public createdFolder$ = this.actions$.pipe(
            ofType(newFolderCreated),
            switchMap(() =>
                this.apiService.create()
                    .pipe(
                        map((folderId) => folderCreateSuccess({ newFolderId: folderId })),
                        catchError(() => {
                            setTimeout(() => {
                                const isLoginPage = location.pathname.includes('login');
                                if (!isLoginPage) {
                                    this.folderService.openAlertDialog(UNABLE_COMMUNICATE_WITH_SERVER);
                                }
                            }, 300);

                            return of();
                        }),
                    ),
            ),
        );

    @Effect()
    public createFolderSuccess$ = this.actions$.pipe(
            ofType(folderCreateSuccess),
            tap((action) => {
                this.folderStore.update({ id: action.newFolderId, projectName: '', matterNumber: '' });
                this.router.navigate(['/lease/upload'], {
                    relativeTo: this.activatedRoute,
                    queryParams: { fid: action.newFolderId },
                });
                this.folderStore.folderReadyToFileUpload();
            }),
        );

    @Effect()
    public folderDetailsUpdated = this.actions$.pipe(
            ofType(folderDetailsUpdated),
            tap((action) => {
                const folder = action.folderDetails;
                const folderId = action.folderId;

                this.folderStore.update(folder);
                this.apiService.updateDetails(folderId, folder).subscribe();
            }),
        );

    constructor(
        private readonly actions$: Actions,
        private readonly folderStore: FolderStore,
        private readonly folderService: FolderService,
        private readonly apiService: FolderApi,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly notificationCenter: NotificationService,
    ) {
    }
}
