import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FolderDetails } from '@core/types';
import { BehaviorSubject, Observable } from 'rxjs';

export type FolderState = FolderDetails & {
    id: string;
};

function createInitialState(): FolderState {
    return {
        id: '',
        projectName: '',
        matterNumber: '',
    };
}

@Injectable()
@StoreConfig({ name: '[leases] folder', resettable: true })
export class FolderStore extends Store<FolderState> {

    private readonly isFolderReadyToFileUpload$ = new BehaviorSubject(false);

    constructor() {
        super(createInitialState());
    }

    public isFolderReadyToFileUpload(): Observable<boolean> {
        return this.isFolderReadyToFileUpload$.asObservable();
    }

    public folderReadyToFileUpload(): void {
        this.isFolderReadyToFileUpload$.next(true);
    }

    public folderReset(): void {
        this.isFolderReadyToFileUpload$.next(false);
    }
}

