import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'avl-error-form',
    templateUrl: './error-form.component.html',
    styleUrls: ['./error-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ErrorFormComponent {
    @Input()
    public buttonText = 'Try Again';

    @Input()
    public isTryAgainButton = true;

    @Input()
    public message = '';

    @Output()
    public tryAgain = new EventEmitter<void>();

    public onTryAgain(): void {
        this.tryAgain.emit();
    }
}
