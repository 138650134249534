/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@datorama/akita-ng-effects';
import { filter, finalize, first, switchMap, take, tap } from 'rxjs/operators';
import { DocumentStore } from './document.store';
import {
    documentAddedToUploading,
    documentDeleted,
    documentStateReset,
    documentUploadCanceled,
    documentUploadFailed,
    documentUploadSuccess,
} from './document.actions';
import { DocumentApi } from '../../api';
import { DocumentService } from './document.service';
import { FolderQuery } from '../folders';
import { convertToMinimalDocument } from '../../types/file-metadata.type';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '../../components/notification-center/services/notification.service';

@Injectable()
export class DocumentEffects {
    @Effect()
    public documentAdded$ = this.actions$.pipe(
            ofType(documentAddedToUploading),
            tap((action) => {
                this.documentStore.addLoadingDocument(action.temporaryFileId, action.fileName);
                this.documentStore.setLoading(true);
                this.documentService.startProgressBarsUpdating();
            }),
        );

    @Effect()
    public documentUploadingCanceled$ = this.actions$.pipe(
            ofType(documentUploadCanceled),
            tap((action) => {
                this.documentStore.remove(action.temporaryFileId);
                this.documentService.checkIsUploadingFinished();
            }),
        );

    @Effect()
    public documentUploadedSuccess$ = this.actions$.pipe(
            ofType(documentUploadSuccess),
            tap((action) => {
                const attemptsCount = 30;

                this.documentStore.update(action.temporaryFileId, { loadingPercentage: DocumentService.loadingLastValue });
                this.documentService.filesMetadataPolling()
                    .pipe(
                        switchMap((folderFileStatus) => of(folderFileStatus.documents.find((doc) => doc.id === action.documentId))),
                        take(attemptsCount),
                        filter((newDocument) => !!newDocument && newDocument.isUploadFinished),
                        first(),
                        finalize(() => {
                            this.documentStore.remove(action.temporaryFileId);
                            this.documentService.checkIsUploadingFinished();
                        }),
                    )
                    .subscribe((document) => {
                        const isDocumentUploadedSuccess = document.isAccepted && !document.isError;
                        if (isDocumentUploadedSuccess) {
                            this.documentStore.add(convertToMinimalDocument(document), { loading: true });
                        } else {
                            this.notificationCenter.highlightBell();
                        }
                    });
            }),
        );

    @Effect()
    public documentUploadFailed$ = this.actions$.pipe(
            ofType(documentUploadFailed),
            tap((action) => {
                this.documentStore.remove(action.temporaryFileId);
                this.documentService.checkIsUploadingFinished();
                this.snackBar.dismiss();
            }),
        );

    @Effect()
    public documentDeleted$ = this.actions$.pipe(
            ofType(documentDeleted),
            tap((action) => {
                const folderId = this.folderQuery.getId();
                const documentId = action.documentId;

                this.documentStore.remove(documentId);
                this.apiService.delete(folderId, documentId).subscribe();
            }),
        );

    @Effect()
    public documentStateReset$ = this.actions$.pipe(
            ofType(documentStateReset),
            tap(() => {
                this.documentStore.reset();
            }),
        );

    constructor(
        private readonly actions$: Actions,
        private readonly documentStore: DocumentStore,
        private readonly apiService: DocumentApi,
        private readonly documentService: DocumentService,
        private readonly folderQuery: FolderQuery,
        private readonly snackBar: MatSnackBar,
        private readonly notificationCenter: NotificationService,
    ) {
    }
}
