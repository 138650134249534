import { Injectable } from '@angular/core';

import { ReportApi } from '../api';

import { DOWNLOAD_REPORTS } from 'app/core/constants';
import { IReportOption } from '../types';

import { FolderQuery, ReportOptionsStore } from '../store';

import { switchMap, tap, throttleTime } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { LoggerService } from '@services';
import { HttpResponse } from '@angular/common/http';
import { IDownloadReport } from '@core/types';

const API_POLLING_INTERVAL = 1000;

@Injectable()
export class ReportService {

    constructor(
        private readonly reportOptionsStore: ReportOptionsStore,
        private readonly reportApi: ReportApi,
        private readonly folderQuery: FolderQuery,
        private readonly logger: LoggerService,
    ) {
    }

    public generateReport(wait: boolean, reportId?: string): Observable<IReportOption[]> {
        const id = reportId || this.folderQuery.getValue().id;

        return this.reportApi.generateReport(id, wait)
            .pipe(
                tap((response) => this.setReportOptions(response)),
            );
    }

    public reportStatus(): Observable<HttpResponse<IReportOption[]>> {
        return timer(API_POLLING_INTERVAL * 3, API_POLLING_INTERVAL * 3)
            .pipe(
                throttleTime(API_POLLING_INTERVAL * 2),
                switchMap(() => this.getReportStatus()),
            );
    }

    public downloadReportFile(key: string, format: string): Observable<HttpResponse<any>> {
        const id = this.folderQuery.getFolderId();

        return this.reportApi.loadReport(id, key, format);
    }

    public resetReport(): void {
        this.reportOptionsStore.reset();
    }

    public markAsDownloaded(id: number): void {
        this.reportOptionsStore.upsert(id, { isDownloaded: true });
    }

    private getReportStatus(): Observable<HttpResponse<IReportOption[]> | null> {
        const folderId = this.folderQuery.getValue().id;

        return folderId
            ? this.reportApi.getReportStatus(folderId)
                .pipe(
                    tap((response) => this.setReportOptions(response.body)),
                )
            : of(null);
    }

    private setReportOptions(response: IReportOption[]): void {
        this.logger.info('setReportOptions', response);
        this.reportOptionsStore.set(this.extendReportOptions(this.filterReportOptions(DOWNLOAD_REPORTS, response), response));
        // this.reportOptionsStore.set(this.produceReportOptions(response));
    }

    private extendReportOptions(existingDownloadReports: IDownloadReport[], response: IReportOption[]): IDownloadReport[] {
        const options: IDownloadReport[] = existingDownloadReports;
        let i = Math.max(...existingDownloadReports.map((dr) => dr.id)) + 1;

        for (const report of response) {
            if (report.bespoke) {
                for (const fmt of report.formats) {
                    options.push({
                        id: i,
                        title: report.name,
                        icon: report.icon,
                        key: report.key,
                        format: fmt,
                        isDownloaded: false,
                    });

                    i++;
                }
            }
        }
        return options;
    }

    private filterReportOptions(source: IDownloadReport[], response: IReportOption[]): IDownloadReport[] {
        return source.filter((sourceReport) => {
            return response.some((responseReport) => {
                return sourceReport.key === responseReport.key
                    && responseReport.formats.includes(sourceReport.format);
            });
        });
    }
}
