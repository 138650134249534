<mat-icon
    *ngIf="notificationBell"
    avlElementOver
    svgIcon="bell"
    class="artificial-bell"
    [template]="notificationBell"
></mat-icon>
<mat-icon
    *ngIf="notificationBell"
    avlElementOver
    class="artificial-bell__triangle"
    svgIcon="triangle"
    [yShift]="28"
    [template]="notificationBell"
></mat-icon>

<div class="notifications">
    <div class="notifications__header">
        <h4 class="notifications__headline">Notification Centre</h4>
        <button
            class="clear-btn-styles notifications__close-btn"
            type="button"
            (click)="close()"
        >
            <mat-icon
                class="notifications__cross"
                svgIcon="close"
            ></mat-icon>
        </button>
    </div>
    <div
        class="notifications__body"
        [ngClass]="{ 'notifications__body--center-text': !errors.length }"
    >
        <p
            *ngIf="!errors.length"
            class="notifications__empty-message"
        >
            No notifications yet, check back later!
        </p>
        <mat-accordion
            *ngIf="errors.length"
            class="error-alert__multiple"
        >
            <mat-expansion-panel class="error-alert__multiple-panel notifications__error--red">
                <mat-expansion-panel-header
                    collapsedHeight="50px"
                    expandedHeight="50px"
                >
                    <mat-icon
                        class="error-alert__icon"
                        svgIcon="alert"
                    ></mat-icon>
                    <p class="error-alert__text">
                        Oops! Some of your documents caused an error, click on this message to see details
                    </p>
                </mat-expansion-panel-header>
                <mat-panel-description class="notifications__error-description">
                    <p
                        *ngFor="let message of errors"
                        class="error-alert__text notifications__description-text"
                    >
                        {{ message }}
                    </p>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
