/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { LoggerService, ProfileService } from '@services';
import { OnboardingManageService } from 'app/onboarding/services';

import { Intercom } from 'ng-intercom';

import { environment } from '@env/environment';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FolderDetails, Logo } from '@core/types';
import { MatMenu } from '@angular/material/menu';
import { NotificationService } from '../../../leases/components/notification-center/services/notification.service';

const defaultAvailLogo: Logo = {
    defaultLogo: true,
    icon: 'assets/images/dark-logo.png',
    iconWidth: 90,
    iconHeight: 24,
};

@Component({
    selector: 'avl-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {

    @Input()
    public profileContextMenu?: MatMenu;

    @Input()
    public folderIsNotCreated: boolean;

    @Input()
    public logo: Logo;

    @Input()
    public homePagePath = 'home';

    @Input()
    public showHamburgerMenu = false;

    @Input()
    public showNotificationBell = false;

    @Input()
    public folderDetails: FolderDetails;

    @Output()
    public newFolderCreated = new EventEmitter<void>();

    @Output()
    public onboardingShowed = new EventEmitter<void>();

    @Output()
    public notificationCenterOpened = new EventEmitter<void>();

    @Output()
    public sideNavToggled = new EventEmitter<void>();

    @Output()
    public projectDetailsOpened = new EventEmitter<void>();

    public logo$ = new BehaviorSubject(defaultAvailLogo);
    public isOnboarding = false;
    public isNewProjectBtnDisabled = false;
    public profileIconReference: Element;
    public isBellHighlighted$: Observable<boolean>;

    constructor(
        private readonly authService: AuthService,
        private readonly intercom: Intercom,
        private readonly profileService: ProfileService,
        private readonly onboarding: OnboardingManageService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly log: LoggerService,
        private readonly notificationCenter: NotificationService,
    ) {
    }

    public ngOnInit(): void {
        this.isBellHighlighted$ = this.notificationCenter.isBellHighlighted();
        this.profileIconReference = document.getElementById('profile');
        this.addSubscriptions();
        this.loadLogo();

        if (environment.enableIntercom) {
            this.loadIntercom();
        }
    }

    public isProviderSso(): boolean {
        return this.authService.isProviderSso();
    }

    public createNewFolder(): void {
        this.onboarding.closeActiveOverlay();
        this.newFolderCreated.emit();
    }

    public openNotificationCenter(): void {
        this.notificationCenterOpened.emit();
    }

    public async logout(): Promise<void> {
        await this.authService.logout();
        this.router.navigate(['/login']);
    }

    public async logoutStay(): Promise<void> {
        await this.authService.logout();
    }

    public isEnvironmentProduction(): boolean {
        return environment.production;
    }

    public showOnboardingAgain(): void {
        this.onboarding.closeActiveOverlay();
        this.onboardingShowed.emit();
    }

    public goHome(): void {
        const routeSnapshot = this.route.snapshot;
        const folderId = routeSnapshot.queryParamMap.get('fid');

        this.router.navigate([this.homePagePath], {
            queryParams: folderId ? { fid: folderId } : null,
        });
    }

    private addSubscriptions(): void {
        const unsubscriber$ = new Subject<void>();
        this.onboarding.isOnboardingActive()
            .pipe(
                takeUntil(unsubscriber$),
                tap((isActive) => {
                    this.isOnboarding = isActive;

                    if (!isActive) {
                        unsubscriber$.next();
                        this.isNewProjectBtnDisabled = true;
                    }
                }),
            )
            .subscribe();

        this.onboarding.createProjectBtnDisabled$
            .pipe(
                takeUntil(unsubscriber$),
                tap((btnDisabled) => this.isNewProjectBtnDisabled = btnDisabled),
            )
            .subscribe();
    }

    private loadLogo(): void {
        this.profileService.getLogo()
            .subscribe((logo) => {
                if (logo.defaultLogo) {
                    this.logo$.next(this.logo || defaultAvailLogo);
                } else {
                    this.logo$.next(logo);
                }
            });
    }

    private loadIntercom(): void {
        this.authService.user$
            .subscribe((user) => {
                const intercomUserData = {
                    app_id: environment.intercomData.appId,
                    widget: environment.intercomData.widget,
                    custom_launcher_selector: environment.intercomData.customLauncherSelector,
                    hide_default_launcher: environment.intercomData.hideDefaultLauncher,
                    user_hash: environment.intercomData.userHash,
                    email: null,
                    created_at: null,
                    user_id: null,
                };

                if (user) {
                    intercomUserData.email = user.email;
                    intercomUserData.user_id = user.uid;
                    intercomUserData.created_at = user.metadata.creationTime;

                    user.getIdTokenResult()
                        .then((idTokenResult) => {
                            intercomUserData.user_hash = idTokenResult.claims.hash;
                            this.intercom.boot(intercomUserData);
                        })
                        .catch((error) => {
                            this.log.error('Unable to set user\'s hash code.', error);
                            this.intercom.boot(intercomUserData);
                        });
                }
            });
    }
}
