import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MinimalBookmark } from '../types/minimal-bookmark.type';
import { ActiveBookmarks } from '../types/active-bookmarks.type';
import { Order } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { BookmarksList } from '../types/bookmarks-list.type';
import { SortDirection } from '@angular/material/sort';

@Injectable()
export class BookmarkApi {

    constructor(private readonly http: HttpClient) {
    }

    public getAll(
        options?: {
      start?: number;
      limit?: number;
      query?: string;
      sort?: string;
      order?: SortDirection;
    }
    ): Observable<BookmarksList> {
        const { start = 0, limit = 50, query = '', sort = 'createdAt', order = Order.DESC } = { ...options };
        const params = new HttpParams()
            .set('start', `${start}`)
            .set('limit', `${limit}`)
            .set('q', query || '')
            .set('sort', sort || 'createdAt')
            .set('order', order === 'desc' || !order ? 'dsc' : order);

        return this.http.get<MinimalBookmark[]>('/api/filed-documents/bookmarks', { params, observe: 'response' })
            .pipe(
                map((response) => {
                    const bookmarks = response.body;
                    const totalBookmarks = +response.headers.get('x-total-count') || bookmarks.length;

                    return {
                        bookmarks,
                        totalBookmarks,
                    };
                }),
            );
    }

    public getActive(): Observable<ActiveBookmarks> {
        return this.http.get<ActiveBookmarks>('/api/filed-documents/bookmarks/active');
    }
}
