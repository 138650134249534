import { createAction, props } from '@datorama/akita-ng-effects';
import { FolderDetails } from '@core/types';

const ACTION_NAME_TAG = '[Leases Folder]';

export const newFolderInitiated = createAction(`${ACTION_NAME_TAG} Folder initiated`);

export const newFolderCreated = createAction(`${ACTION_NAME_TAG} Folder created`);

export const folderCreateSuccess = createAction(
    `${ACTION_NAME_TAG} Folder creating success`,
    props<{ newFolderId: string }>(),
);

export const folderDetailsUpdated = createAction(
    `${ACTION_NAME_TAG} Folder details updated`,
    props<{ folderId: string; folderDetails: FolderDetails }>(),
);
