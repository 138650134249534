<div
    *ngIf="!invalidCode && !model.hasError && !succeedSend"
    class="auth-page__form-title"
>
    <h2>Choose a New Password</h2>
</div>
<form
    *ngIf="!invalidCode && !model.hasError && !succeedSend"
    class="auth-page__form"
    #resetForm="ngForm"
    (ngSubmit)="submitReset()"
    [ngFormOptions]="{ updateOn: 'blur' }"
>
    <div class="form-field">
        <label>New Password</label>
        <input
            #newPassword="ngModel"
            placeholder="New Password"
            autocomplete="new-password"
            autofocus
            tabindex="1"
            name="new-password"
            [ngClass]="{'error': model.newPassword !== null && !model.newPassword?.length}"
            [disabled]="invalidCode"
            [type]="isNewPasswordVisible ? 'text' : 'password'"
            [(ngModel)]="model.newPassword"
        />
        <mat-icon
            class="button"
            [svgIcon]="isNewPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isNewPasswordVisible = !isNewPasswordVisible"
        ></mat-icon>
        <mat-error *ngIf="model.newPassword !== null && !model.newPassword?.length">A password is required</mat-error>
    </div>
    <div class="form-field">
        <label>Confirm Password</label>
        <input
            #confirmNewPassword="ngModel"
            tabindex="2"
            name="confirm-new-password"
            autocomplete="new-password"
            placeholder="Confirm Password"
            [disabled]="invalidCode"
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
            [(ngModel)]="model.confirmNewPassword"
        />
        <mat-icon
            class="button"
            [svgIcon]="isConfirmPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"
        ></mat-icon>
        <mat-error *ngIf="model.confirmNewPassword !== null && !model.confirmNewPassword?.length">
            Confirm password is required
        </mat-error>
    </div>
    <button
        class="avl-btn avl-btn--blue avl-btn--wide"
        tabindex="3"
        value="Reset password"
        type="submit"
        [disabled]="invalidCode || !model.newPassword?.length"
    >
        Reset password
    </button>
</form>
<avl-error-form
    *ngIf="model.hasError"
    [message]="model.errorMessage"
    [isTryAgainButton]="isTryAgainButton"
    (tryAgain)="onTryAgain()"
></avl-error-form>
<avl-succeed-form
    *ngIf="succeedSend"
    [message]="succeedMessage"
    [isResendButton]="false"
    (resend)="onTryAgain()"
></avl-succeed-form>
