import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IDownloadReport } from '@core/types';

export type ReportOptionsState = EntityState<IDownloadReport, number>;

@Injectable()
@StoreConfig({ name: 'report-options', resettable: true })
export class ReportOptionsStore extends EntityStore<ReportOptionsState, IDownloadReport> {
    constructor() {
        super();
    }
}

