import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { CREATE_NEW_FOLDER_CONFIRM_DATA } from '@constants';
import { filter, tap } from 'rxjs/operators';
import { compilingFinishChecked, folderDetailsUpdated, FolderQuery, FolderService, newFolderInitiated } from './states';
import { Actions } from '@datorama/akita-ng-effects';
import { MatSidenav } from '@angular/material/sidenav';
import { EventType, FolderDetailsData } from '@shared/components/dialogs/folder-details-dialog/folder-details-dialog.component';
import { FolderDetails, Logo } from '@core/types';
import { AuthService } from '@auth/services';
import { NotificationService } from './components/notification-center/services/notification.service';
import { environment } from '@env/environment';
import { resetStores } from '@datorama/akita';
import { HttpQueryParamsService, ProfileService } from '@services';

@Component({
    selector: 'avl-leases',
    templateUrl: './leases.component.html',
    styleUrls: ['./leases.component.scss'],
})
export class LeasesComponent implements OnInit, OnDestroy {

    public readonly leaseLogo: Logo = {
        defaultLogo: true,
        icon: 'assets/images/avail-lease-logo-beta.svg',
        iconWidth: 169,
        iconHeight: 20,
    };

    @ViewChild(MatSidenav, { static: false })
    public sidenavComponent: MatSidenav;

    public folderIsNotCreated$: Observable<boolean>;
    public folderDetails$: Observable<FolderDetails>;

    public profileIconReference: Element;

    constructor(
        private readonly authService: AuthService,
        private readonly actions: Actions,
        private readonly profileService: ProfileService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly folderQuery: FolderQuery,
        private readonly folderService: FolderService,
        private readonly notificationService: NotificationService,
        private readonly queryParamsService: HttpQueryParamsService,
    ) {
    }

    public ngOnInit(): void {
        this.actions.dispatch(compilingFinishChecked());

        this.folderDetails$ = this.folderQuery.getDetails$();
        this.folderIsNotCreated$ = this.folderQuery.select((state) => !state.id);
        this.profileIconReference = document.getElementById('profile');
        this.profileService.loadConfig();
    }

    public ngOnDestroy(): void {
        resetStores();
    }

    public onNewFolderCreated(): void {
        const isFolderAlreadyCreated = this.folderQuery.isCreated();

        if (isFolderAlreadyCreated) {
            const config = {
                panelClass: 'confirm-dialog',
                data: { ...CREATE_NEW_FOLDER_CONFIRM_DATA, color: 'dark-purple' },
            };

            this.dialog.open(ConfirmDialogComponent, config)
                .afterClosed()
                .pipe(
                    filter((isConfirm) => isConfirm),
                    tap(() => this.actions.dispatch(newFolderInitiated())),
                )
                .subscribe();
        } else {
            this.actions.dispatch(newFolderInitiated());
        }

        this.sidenavComponent?.close();
    }

    public async logout(): Promise<void> {
        const queryParams = this.queryParamsService.addRedirectToQueryParams({ isOtherParamsIncluded: false });

        await this.authService.logout();
        this.router.navigate(['/login'], { queryParams });
    }

    public onFolderDetailsOpen(): void {
        const folderDetails = this.folderQuery.getDetails();
        this.folderService.openFolderDetailsDialog(folderDetails)
            .pipe(
                filter((result) => !!result),
            )
            .subscribe((result: FolderDetailsData) => {
                if (result.event === EventType.confirm) {
                    const folderId = this.folderQuery.getId();
                    this.actions.dispatch(folderDetailsUpdated({ folderId, folderDetails: result.data }));
                }
            });
    }

    public onNotificationCenterOpened(): void {
        const folderId = this.folderQuery.getId();
        this.notificationService.showDialog(folderId);
    }

    public isProviderSso(): boolean {
        return this.authService.isProviderSso();
    }

    public async logoutStay(): Promise<void> {
        await this.authService.logout();
    }

    public isEnvironmentProduction(): boolean {
        return environment.production;
    }
}
