import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { LoggerService, ProfileService } from '@services';
import { OnboardingManageService } from 'app/onboarding/services';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoutingService {
    public sso$ = new BehaviorSubject<string>(null);

    constructor(
        private readonly profileService: ProfileService,
        private readonly router: Router,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
    ) {
    }

    public updateSsoQueryParam(next: ActivatedRouteSnapshot): void {
        if (next.queryParamMap.has('sso') && next.queryParamMap.get('sso')) {
            this.sso$.next(next.queryParamMap.get('sso'));
        }
    }

    public isSso(next: ActivatedRouteSnapshot): boolean {
        return !!(next.queryParamMap.has('sso') && next.queryParamMap.get('sso'));
    }

    public getSso(next: ActivatedRouteSnapshot): string {
        return next.queryParamMap.get('sso');
    }

    public navigateToHome(routeSnapshot: ActivatedRouteSnapshot): void {
        this.parseHomeUrl(routeSnapshot)
            .pipe(take(1))
            .subscribe((url) => this.router.navigateByUrl(url));
    }

    public parseHomeUrl(routeSnapshot: ActivatedRouteSnapshot): Observable<UrlTree> {
        this.profileService.ensureProfile().pipe(take(1))
            .subscribe();

        return this.profileService
            .shouldShowOnboarding()
            .pipe(
                map((show) => {
                    const redirect = routeSnapshot.queryParamMap.get('redirect');
                    const ssoQueryParams = this.isSso(routeSnapshot) ? `?sso=${this.getSso(routeSnapshot)}` : '';
                    this.log.info('shouldShowOnboarding.subscribe', show);

                    if (show) {
                        this.onboarding.start();
                        this.profileService.onboardingShown().subscribe();
                        return this.router.parseUrl(`/onboarding${ssoQueryParams}`);
                    } else {
                        if (redirect) {
                            const redirectUrl = this.router.parseUrl(decodeURIComponent(redirect));
                            if (this.isSso(routeSnapshot)) {
                                redirectUrl.queryParams.sso = this.getSso(routeSnapshot);
                            }
                            return redirectUrl;
                        }
                        return this.router.parseUrl('/home');
                    }
                }));
    }
}
