<mat-icon
    avlElementOver
    [template]="notificationBell"
    svgIcon="bell"
    class="artificial-bell"
    (click)="openNotificationDialog()"
></mat-icon>
<mat-icon
    avlElementOver
    [template]="notificationBell"
    [yShift]="-5"
    [xShift]="12"
    svgIcon="explanation-triangle"
    class="artificial-bell__explanation-triangle"
    (click)="openNotificationDialog()"
></mat-icon>

<div class="report-dialog">
    <img
        src="../../../../../../assets/images/something-wrong.svg"
        alt="Lease report generation fail"
    >
    <div class="report-dialog__info">
        <h3 class="report-dialog__title">{{ title }}</h3>
        <p class="report-dialog__description">{{ message }}</p>
    </div>
</div>

