import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReportOptionsState, ReportOptionsStore } from './report-options.store';

@Injectable()
export class ReportOptionsQuery extends QueryEntity<ReportOptionsState> {
    constructor(protected store: ReportOptionsStore) {
        super(store);
    }
}
