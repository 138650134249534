<table
    data-testid="land-registry-result-table"
    mat-table
    [dataSource]="titlesSource"
    class="collection__table lr-result-table"
>
    <!-- Reference Column -->
    <ng-container matColumnDef="reference">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            Reference
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.titleNumber }}
        </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            Type
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.ownership }}
        </td>
    </ng-container>

    <!-- Date Column -->
    <!-- <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.purchasedAt | date:'d MMMM y' }}</td>
    </ng-container> -->

    <!-- Address Column -->
    <ng-container matColumnDef="address">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            Address
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.address }}
        </td>
    </ng-container>

    <!-- Proprietors Column -->
    <ng-container matColumnDef="proprietors">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            Proprietor(s)
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.proprietors.length < 100 ? element.proprietors : (element.proprietors | slice:0:70) + '...'}}
        </td>
    </ng-container>

    <!-- Company Number Column -->
    <ng-container matColumnDef="companyNumbers">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            Company Number(s)
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{
                element.companyNumbers?.length < 100
                ? element.companyNumbers
                : (element.companyNumbers | slice:0:70) + '...'
            }}
        </td>
    </ng-container>

    <!-- Alerts Column -->
    <!--
          <ng-container matColumnDef="alerts">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              &lt;!&ndash; <mat-icon svgIcon="sync"
                        matTooltip="You have already added this title to your project. Add this to replace with an up-to-date title."
                        matTooltipPosition="above">
              </mat-icon>
              <mat-icon svgIcon="alert-circle"
                        matTooltip="Applications are pending against this title and it is backdated to 10 December 2019."
                        matTooltipPosition="above">
              </mat-icon> &ndash;&gt;
            </td>
          </ng-container>
    -->

    <!-- Select Column -->
    <ng-container matColumnDef="select">
        <th
            mat-header-cell
            *matHeaderCellDef
        >
            <label
                *ngIf="titles.length"
                class="collection__add-all-control"
            >
                <input
                    type="checkbox"
                    class="checkbox-input"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                >
                <mat-icon svgIcon="add-all-checkbox"></mat-icon>
                <span class="checkbox-text">Add All</span>
            </label>
        </th>
        <td
            mat-cell
            *matCellDef="let row"
        >
            <label class="collection__add-control">
                <input
                    type="checkbox"
                    class="checkbox-input"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? toggleSelection(row) : null"
                    [checked]="isSelected(row)"
                >
                <div class="collection__add-label"></div>
            </label>
        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
        mat-row
        [hidden]="loading"
        *matRowDef="let row; columns: displayedColumns;"
    ></tr>
</table>
<avl-table-loading-placeholder
    *ngIf="loading"
    [columns]="displayedColumns"
    [size]="tableRowsAmount"
></avl-table-loading-placeholder>
<avl-table-no-data-disclaimer
    *ngIf="!loading && !titles.length"
    [iconName]="'land-registry-logo-grey'"
    [message]="'No titles found'"
></avl-table-no-data-disclaimer>
