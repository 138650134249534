import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { LoggerService } from '@services';
import { Credentials, defaultCredentials } from '@auth/types/credentials.type';

@Component({
    selector: 'avl-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ResetPasswordComponent implements OnInit {
    public model: Credentials = defaultCredentials();
    public code: string;
    public invalidCode = false;
    public data: any;
    public isNewPasswordVisible = false;
    public isConfirmPasswordVisible = false;
    public succeedSend = false;
    public isTryAgainButton = false;
    public succeedMessage = 'The password was successfully changed, click here to login.';

    constructor(
        private readonly authService: AuthService,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly log: LoggerService,
    ) {
    }

    public ngOnInit(): void {
        this.data = this.route.snapshot.data;
        this.log.info('ngOnInit', this.data);

        if (this.data && this.data.actionCode) {
            this.model.email = this.data.actionCode.email;
            this.code = this.data.actionCode.code;

            if (!this.data.actionCode.valid) {
                this.model.hasError = true;
                this.model.errorMessage = this.data.actionCode.errorMessage;
                this.invalidCode = true;
            }
        } else {
            this.model.hasError = true;
            this.model.errorMessage = 'Invalid reset code, please check the link that has been sent to your email again.';
            this.invalidCode = true;
        }

        this.model.newPassword = null;
    }

    public onTryAgain(): void {
        this.model.hasError = false;
        this.model.errorMessage = null;
        this.succeedSend = false;
    }

    public submitReset(): void {
        this.model.hasError = false;
        this.model.errorMessage = null;
        this.succeedSend = false;
        this.isTryAgainButton = false;

        if (!this.model.newPassword) {
            this.log.info('this.resetForm.invalid');
            this.model.hasError = true;
            this.model.errorMessage = 'A password is required.';
            this.ref.detectChanges();

            return;
        }

        if (this.model.newPassword !== this.model.confirmNewPassword) {
            this.model.hasError = true;
            this.model.errorMessage = 'The new and confirm passwords do not match.';
            this.log.info(this.model.errorMessage);
            this.isTryAgainButton = true;
            this.ref.detectChanges();

            return;
        }

        this.authService.confirmPasswordReset(this.code, this.model.newPassword)
            .then((value) => {
                this.log.info('Nice, it worked!', value);
                this.authService.logout().then(() => {
                    this.succeedSend = true;
                });
            })
            .catch((err) => {
                this.log.info('Something went wrong:', err.message);
                this.model.hasError = true;
                this.model.errorMessage = err.message;
                this.isTryAgainButton = true;
                this.ref.detectChanges();
            });
    }
}
