import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { LandRegistryApi } from '../api';

import { ITileNumberForPurchase, ITitleInfo } from '../types';

import { catchError, concatMap, delay, expand, filter, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { SearchResultsStore } from '../store';
import { PurchasedTitleDetails } from '../types/purchased-title-details.type';

@Injectable()
export class LandRegistrySearchService {

    constructor(
        private readonly landRegistryApi: LandRegistryApi,
        private readonly searchResultStore: SearchResultsStore,
    ) {
    }

    public searchTitleRegisters(folder: string, kind: string, query: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        this.searchResultStore.setLoading(true);
        statusUrl.next(null);
        return this.landRegistryApi.search(folder, kind, query)
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
                switchMap((url) => this.getSearchStatus(url, statusUrl)),
                catchError((error) => {
                    this.searchResultStore.setLoading(false);
                    return throwError(error);
                }),
            );
    }

    public downloadSearchResults(url: string): Observable<HttpResponse<any>> {
        return this.landRegistryApi.downloadSearchResults(url);
    }

    public refreshTitles(folder: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        this.searchResultStore.setLoading(true);

        return this.landRegistryApi.refresh(folder)
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
                switchMap((url) => this.getSearchStatus(url, statusUrl)),
                catchError((error) => {
                    this.searchResultStore.setLoading(false);
                    return throwError(error);
                }),
            );
    }

    public resetState(): void {
        this.searchResultStore.reset();
    }

    public removeTitleRegister(titleNumber: string): void {
        this.searchResultStore.remove(titleNumber);
    }

    public purchaseTitles(folder: string, titles: ITitleInfo[]): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const titleNumbers: ITileNumberForPurchase[] = titles.map((item) => {
            return { kind: 'title-register', reference: item.titleNumber };
        });

        return this.landRegistryApi.purchaseTitles(folder, titleNumbers).pipe(
            map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
            concatMap((url) => this.getPurchaseStatus(url)),
        );
    }

    private getSearchStatus(url: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        const searchStatus$ = this.landRegistryApi.getSearchStatus(url);

        return searchStatus$.pipe(
            expand((response) => {
                return response.status === 202
                    ? searchStatus$.pipe(delay(2 * 1000))
                    : EMPTY;
            }),
            filter((response) => {
                return response.status !== 202 || !!response.body?.length;
            }),
            map((response) => {
                this.searchResultStore.set(response.body);

                if (statusUrl) {
                    statusUrl.next(url.replace('/search/', '/search-to-excel/'));
                }

                this.searchResultStore.setLoading(false);

                return response.body;
            }),
        );
    }

    private getPurchaseStatus(url: string): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const purchaseStatus$ = this.landRegistryApi.getPurchaseStatus(url);

        return purchaseStatus$.pipe(
            expand((response) => {
                return response.status === 202
                    ? purchaseStatus$.pipe(delay(3000))
                    : EMPTY;
            }),
        );
    }
}
