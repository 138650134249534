<header class="header">
    <button
        *ngIf="showHamburgerMenu"
        class="header__menu"
        data-testid="side-bar-menu-button"
        type="button"
        mat-icon-button
        (click)="sideNavToggled.emit()"
    >
        <mat-icon svgIcon="hamburger-menu"></mat-icon>
    </button>
    <img
        class="header__logo"
        alt="Logo"
        [src]="(logo$ | async).icon | trustUrl"
        [style.width]="(logo$ | async)?.iconWidth + 'px'"
        [style.height]="(logo$ | async).iconHeight + 'px'"
        (click)="goHome()"
    >
    <div
        *ngIf="folderDetails?.projectName"
        class="header__details"
        (click)="projectDetailsOpened.emit()"
    >
        <span>{{ folderDetails.projectName }} | {{ folderDetails.matterNumber }}</span>
    </div>
    <div class="header__nav">
        <div
            avlElevate
            class="header__nav-btn-wrap"
            name="new-project-button"
        >
            <button
                class="header__nav-btn opacity"
                (click)="createNewFolder()"
            >
                <!--[disabled]="folderIsNotCreated || isNewProjectBtnDisabled"-->
                <mat-icon svgIcon="add"></mat-icon>
            </button>
        </div>
        <div
            *ngIf="showNotificationBell"
            avlElevate
            class="header__nav-btn-wrap"
            name="notification-bell"
        >
            <button
                class="header__nav-btn relative"
                (click)="openNotificationCenter()"
            >
                <mat-icon
                    class="notifications-bell opacity"
                    svgIcon="bell"
                ></mat-icon>
                <mat-icon
                    *ngIf="isBellHighlighted$ | async"
                    svgIcon="explanation-triangle"
                    class="header__explanation-triangle"
                ></mat-icon>
            </button>
        </div>
        <div
            class="header__nav-btn-wrap"
            avlElevate
            name="support-button"
        >
            <button
                id="support"
                class="header__nav-btn opacity"
                [disabled]="isOnboarding"
            >
                <mat-icon svgIcon="support"></mat-icon>
            </button>
        </div>
        <div class="header__nav-btn-wrap">
            <button
                class="header__nav-btn opacity"
                [matMenuTriggerFor]="profileContextMenu || defaultProfileMenu"
                [disabled]="isOnboarding"
            >
                <mat-icon
                    id="profile"
                    svgIcon="profile"
                ></mat-icon>
            </button>
        </div>
        <ng-content select="[profileContextMenu]"></ng-content>
        <mat-menu
            #defaultProfileMenu="matMenu"
            class="profile-menu"
        >
            <mat-icon
                avlElementOver
                svgIcon="triangle"
                class="profile-menu__arrow"
                [template]="profileIconReference"
                [yShift]="26"
            ></mat-icon>
            <button
                *ngIf="!isProviderSso()"
                mat-menu-item
                (click)="logout()"
            >
                Log Out
            </button>
            <button
                *ngIf="isProviderSso()"
                mat-menu-item
                (click)="logout()"
            >
                Log Out from Avail
            </button>
            <button
                *ngIf="!isEnvironmentProduction()"
                mat-menu-item
                (click)="logoutStay()"
            >
                Clear Auth Session
            </button>
            <button
                *ngIf="onboardingShowed.observed"
                mat-menu-item
                class="context-menu__button"
                (click)="showOnboardingAgain()"
            >
                Show onboarding again
            </button>
        </mat-menu>
    </div>
</header>
