import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DocumentStore, FolderStore } from '../states';
import { FolderApi } from '../api';
import { convertToMinimalDocument } from '../types/file-metadata.type';
import { FolderDetails } from '@core/types';
import { NotificationService } from '../components/notification-center/services/notification.service';


@Injectable()
export class LeasesResolver implements Resolve<FolderDetails> {

    constructor(
        private readonly documentStore: DocumentStore,
        private readonly folderStore: FolderStore,
        private readonly folderApi: FolderApi,
        private readonly router: Router,
        private readonly notificationCenter: NotificationService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<FolderDetails> {
        const folderId = route.queryParams.fid;
        const isFolderIdNew = this.folderStore.getValue().id !== folderId;

        if (folderId && isFolderIdNew) {
            return this.updateFolderStatus(folderId);
        }
    }

    private updateFolderStatus(folderId: string): Observable<FolderDetails> {
        return this.folderApi.getFileStatus(folderId)
            .pipe(
                switchMap((status) => {
                    const documents = status.documents
                        .filter((doc) => {
                            const isDocCorrect = doc.isAccepted && !doc.isError;
                            if (!isDocCorrect) {
                                this.notificationCenter.highlightBell();
                            }

                            return isDocCorrect;
                        })
                        .map((doc) => convertToMinimalDocument(doc));

                    this.documentStore.set(documents);
                    this.folderStore.update({ id: status.id });
                    this.folderStore.folderReadyToFileUpload();

                    return this.updateFolderDetails(folderId);
                }),
                catchError(() => {
                    this.router.navigate(['lease']);

                    return NEVER;
                }),
            );
    }

    private updateFolderDetails(folderId: string): Observable<FolderDetails> {
        return this.folderApi.getDetails(folderId)
            .pipe(
                tap((folderDetails) => {
                    this.folderStore.update({ projectName: folderDetails.projectName, matterNumber: folderDetails.matterNumber });
                }),
            );
    }
}
