import { createAction, props } from '@datorama/akita-ng-effects';

const ACTION_NAME_TAG = '[Leases Compile]';

export const compilingFinishChecked = createAction(`${ACTION_NAME_TAG} Compilation process state checked`);

export const compilingStarted = createAction(
    `${ACTION_NAME_TAG} Compiling process started`,
    props<{ folderId: string }>(),
);

export const compilingSuccess = createAction(
    `${ACTION_NAME_TAG} Compiling process success`,
    props<{
    title: string;
    message: string;
    linkToReport: string;
    linkMessage: string;
    linkPlaceholder: string;
  }>()
);

export const compilingPartialSuccess = createAction(
    `${ACTION_NAME_TAG} Compiling process partial success`,
    props<{
    title: string;
    firstMessage: string;
    secondMessage: string;
    linkToReport: string;
    linkMessage: string;
    linkPlaceholder: string;
  }>()
);

export const compilingFailed = createAction(
    `${ACTION_NAME_TAG} Compiling process failed`,
    props<{ title: string; message: string }>()
);

export const somethingWentWrong = createAction(
    `${ACTION_NAME_TAG} Something went wrong`,
    props<{ title?: string; message?: string }>()
);

export const reportDownloaded = createAction(
    `${ACTION_NAME_TAG} Report downloaded`,
    props<{ reportLink: string; folderId?: string }>()
);
