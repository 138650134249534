import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

import { takeUntil, tap } from 'rxjs/operators';
import { merge, Observable, Subject, Subscription } from 'rxjs';

import { fadeRowAnimation } from 'app/core/animations/fade.animation';

import { IPurchasedFile } from 'app/titles/types';

import { TableRowsAmountService } from '@services';
import { ICollectionHeightOffset, ICollectionPageEvent, IPagination } from '@core/types';

const purchasedTableHeightOffset: ICollectionHeightOffset = {
    header: 64,
    tableHeader: 88,
    paginator: 56,
    footerButton: 98,
    tableHeaderColumns: 44,
};

@Component({
    selector: 'avl-purchased-files-table',
    templateUrl: './purchased-files-table.component.html',
    styleUrls: ['./purchased-files-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeRowAnimation],
})
export class PurchasedFilesTableComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @Input()
    public loading: boolean;

    @Input()
    public files: IPurchasedFile[];

    @Input()
    public pagination: IPagination;

    @Input()
    public resetSelection$: Observable<boolean>;

    @Output()
    public pageChanged = new EventEmitter<ICollectionPageEvent>();

    @Output()
    public selectedFiles = new EventEmitter<IPurchasedFile[]>();

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;

    public displayedColumns: string[] = ['document', 'reference', 'purchasedAt', 'date', 'project', 'matter', 'entryNumber', 'select'];
    public selection = new SelectionModel<IPurchasedFile>(true, []);
    public filesSource = new MatTableDataSource<IPurchasedFile>([]);
    public tableRowsAmount: number;

    private readonly destroy$ = new Subject<void>();
    private resetSelectionSubscription: Subscription;

    constructor(
        private readonly tableRowsAmountService: TableRowsAmountService,
    ) {
    }

    public ngOnInit(): void {
        this.calculateRowsAmount();
        this.resetToFirstPage();
        this.resetSelectionSubscription = this.resetSelection$
            .subscribe((b) => {
                if (b) {
                    this.deselect(...this.selection.selected);
                    this.selection.clear();
                    this.selectedFiles.emit(this.selection.selected);
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.files) {
            this.filesSource.data = this.files;
        }
    }

    public ngAfterViewInit(): void {
        this.sort.sortChange
            .subscribe(() => this.paginator.pageIndex = 0);

        this.setupPageChangeListener();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
        this.resetSelectionSubscription.unsubscribe();
    }

    public isAllSelected(): boolean {
        const selectedIds = this.selection.selected.map((file) => file.documentId);
        const sourceIds = this.filesSource.data.map((file) => file.documentId);
        return sourceIds.every((id) => selectedIds.includes(id));
    }

    public masterToggle(): void {
        this.isAllSelected()
            ? this.deselect(...this.filesSource.data)
            : this.filesSource.data.forEach((row) => !this.isSelected(row) && this.selection.select(row));

        this.selectedFiles.emit(this.selection.selected);
    }

    public resetToFirstPage(): void {
        this.paginator.pageIndex = 0;
        this.paginator.firstPage();
        this.pageChanged.emit({
            pageIndex: 0,
            pageSize: this.tableRowsAmount,
        });
    }

    public toggleSelection(value: IPurchasedFile): void {
        this.isSelected(value)
            ? this.deselect(value)
            : this.selection.select(value);

        this.selectedFiles.emit(this.selection.selected);
    }

    public isSelected(file: IPurchasedFile): boolean {
        return !!this.selection.selected.find((selectedFile) => selectedFile.documentId === file.documentId);
    }

    public deselect(...files: IPurchasedFile[]): void {
        files.forEach((file) => {
            const originFile = this.selection.selected.find((selectedFile) => selectedFile.documentId === file.documentId);
            if (originFile) {
                this.selection.deselect(originFile);
            }
        });
    }

    public trackPurchasedFiles(index: number, item: IPurchasedFile): string {
        return `${item.documentId}`;
    }

    private calculateRowsAmount(): void {
        this.tableRowsAmount = this.tableRowsAmountService.calculateRowsAmount(purchasedTableHeightOffset);
    }

    private setupPageChangeListener(): void {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.pageChanged.emit({
                        pageIndex: this.paginator.pageIndex,
                        pageSize: this.paginator.pageSize,
                        sort: this.sort.active,
                        order: this.sort.direction,
                    });
                }))
            .subscribe();
    }
}
