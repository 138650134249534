/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@datorama/akita-ng-effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BookmarkApi } from '../../api';
import { of } from 'rxjs';
import { BookmarkStore } from './bookmark.store';
import { bookmarksLoaded, bookmarksLoadingFailed, bookmarksLoadingSuccess, bookmarksSearched, bookmarksSorted } from './bookmark.actions';

@Injectable()
export class BookmarkEffects {
    @Effect({ dispatch: true })
    public bookmarksLoaded$ = this.actions$.pipe(
            ofType(bookmarksLoaded),
            tap(() => this.bookmarkStore.setLoading(true)),
            switchMap((action) =>
                this.apiService.getAll({
                    start: action.params.pageIndex,
                    limit: action.params.pageSize,
                    sort: action.params.sort,
                    order: action.params.order,
                })
                    .pipe(
                        map((bookmarksList) => bookmarksLoadingSuccess({ bookmarksList })),
                        catchError((error) => of(bookmarksLoadingFailed({ error }))),
                    ),
            ),
        );

    @Effect({ dispatch: true })
    public bookmarksSearched$ = this.actions$.pipe(
            ofType(bookmarksSearched),
            tap(() => this.bookmarkStore.setLoading(true)),
            switchMap((action) => {
                const page = action.params.page;
                const query = action.params.search;

                return this.apiService.getAll({
                    start: page.pageIndex,
                    limit: page.pageSize,
                    sort: page.sort,
                    order: page.order,
                    query,
                })
                    .pipe(
                        map((bookmarksList) => bookmarksLoadingSuccess({ bookmarksList })),
                        catchError((error) => of(bookmarksLoadingFailed({ error }))),
                    );
            }),
        );

    @Effect({ dispatch: true })
    public bookmarksSorted$ = this.actions$.pipe(
            ofType(bookmarksSorted),
            tap(() => this.bookmarkStore.setLoading(true)),
            switchMap((action) => {
                const params = action.params;

                return this.apiService.getAll({
                    start: params.pageIndex * params.pageSize,
                    limit: params.pageSize,
                    sort: params.sort,
                    order: params.order,
                })
                    .pipe(
                        map((bookmarksList) => bookmarksLoadingSuccess({ bookmarksList })),
                        catchError((error) => of(bookmarksLoadingFailed({ error }))),
                    );
            }),
        );

    @Effect()
    public bookmarkLoadingSuccess$ = this.actions$.pipe(
            ofType(bookmarksLoadingSuccess),
            tap((action) => {
                this.bookmarkStore.set(action.bookmarksList.bookmarks);
                this.bookmarkStore.update({ totalCount: action.bookmarksList.totalBookmarks });
            }),
        );

    @Effect()
    public bookmarkLoadingFailed$ = this.actions$.pipe(
            ofType(bookmarksLoadingFailed),
            tap((action) => {
                this.bookmarkStore.setLoading(false);
                this.bookmarkStore.setError(action.error);
            }),
        );

    constructor(
        private readonly actions$: Actions,
        private readonly bookmarkStore: BookmarkStore,
        private readonly apiService: BookmarkApi,
    ) {
    }
}
