import { IOverlayConfig } from '../types/overlay-config.type';

export const splashScreen: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    content: {
        type: 'splash',
        positionX: 'center',
        positionY: 'center',
        backdropClickSkip: true,
    },
};

export const characterWelcome: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'backdrop-transparent',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'bottom',
        cardText: ['Welcome to your 30 second guide to using <b>Avail</b>! Get ready to supercharge your title due diligence 🚀'],
        cardAction: 'Click anywhere to continue...',
        sourcePath: 'assets/bodymovin/kate_straight_bottom_left.json',
        backdropClickSkip: false,
    },
};

export const startDragging: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'bottom',
        highlightElement: 'drop-area',
        cardWidth: '370px',
        cardText: [
            'Start by <b>dragging</b> a title register into this box or just <b>click</b> on “Upload”',
            'It must be a PDF downloaded from either <b>HM Land Registry</b> or <b>ScotLis</b>. We can\'t analyse scanned documents 📄',
            'No title to hand? Drag in <a href="assets/test-title.pdf" target="_blank">this</a> one',
        ],
        sourcePath: 'assets/bodymovin/kate_bottom_right.json',
        backdropClickSkip: true,
    },
};

export const projectDetails: IOverlayConfig = {
    hasBackdrop: false,
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'bottom',
        cardText: [
            'Let’s get started by putting in some <b>Project Details<b>',
        ],
        cardAction: 'Enter details and tap ‘Let’s Go’ to continue',
        sourcePath: 'assets/bodymovin/kate_bottom_right.json',
        backdropClickSkip: true,
    },
};

export const firstUploadMedal: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'medal',
        positionX: 'center',
        positionY: 'center',
        sourcePath: 'assets/bodymovin/medal_first_document.json',
        backdropClickSkip: false,
    },
};

export const giveAnotherTry: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'top',
        cardWidth: '240px',
        cardText: [
            'Oops, that doesn\'t seem to be a valid title register.',
            'Please give it another try!',
        ],
        highlightElement: 'drop-area',
        sourcePath: 'assets/bodymovin/kate_top_right.json',
        backdropClickSkip: true,
    },
};

export const reviewTitles: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'top',
        highlightElement: 'titles-table',
        cardText: [
            'This is where you can review the title register you have just uploaded',
        ],
        cardAction: 'Click anywhere to continue...',
        sourcePath: 'assets/bodymovin/kate_top_right.json',
        backdropClickSkip: false,
    },
};

export const uploadMoreTitles: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'bottom',
        cardWidth: '232px',
        highlightElement: 'drop-area',
        cardText: [
            'Or upload more title registers',
        ],
        cardAction: 'Click anywhere to continue...',
        sourcePath: 'assets/bodymovin/kate_bottom_left.json',
        backdropClickSkip: false,
    },
};

export const startAnalyzing: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'top',
        cardWidth: '291px',
        highlightElement: 'next-button',
        cardText: [
            'But for now, let’s start analysing them.',
        ],
        cardAction: 'Click on <b>‘Next’</b> below',
        sourcePath: 'assets/bodymovin/kate_top_left.json',
        backdropClickSkip: true,
    },
};

export const addSchedule: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'bottom',
        cardWidth: '283px',
        highlightElement: 'schedules-table-row-0',
        cardText: [
            'This is where you can add <b>Schedules</b> to your report, including supporting documents and additional analysis - build your own!',
        ],
        cardAction: 'Click <b>‘ADD FOR FREE’</b> to add this Schedule (you won’t be charged).',
        sourcePath: 'assets/bodymovin/kate_bottom_left.json',
        backdropClickSkip: true,
    },
};

export const scheduleSuccessfullyWithReport: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'center',
        cardWidth: '283px',
        highlightElement: 'next-btn',
        cardText: ['Great! You’ve added this successfully.'],
        cardAction: 'Click on ‘Next’ below',
        sourcePath: 'assets/bodymovin/kate_top_left.json',
        backdropClickSkip: true,
    },
};

export const scheduleSuccessfullyWithoutReport: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'center',
        cardWidth: '283px',
        cardText: ['Great! You’ve added this successfully.'],
        cardAction: 'Click anywhere to continue',
        sourcePath: 'assets/bodymovin/kate_top_left.json',
        backdropClickSkip: false,
    },
};

export const showAiMagic: IOverlayConfig = {
    hasBackdrop: false,
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'top',
        cardWidth: '233px',
        cardText: [
            'This is where the <b>artificial intelligence magic</b> happens.',
            'It only takes a few seconds!',
        ],
        sourcePath: 'assets/bodymovin/kate_top_right.json',
        backdropClickSkip: true,
    },
};

export const downloadReport: IOverlayConfig = {
    hasBackdrop: false,
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'bottom',
        cardText: [
            'This is where you can download the finished report you need.',
            'Go ahead and select a <b>Key Issues</b> report.',
        ],
        sourcePath: 'assets/bodymovin/kate_bottom_right.json',
        backdropClickSkip: true,
    },
};

export const waitingForReportDownload: IOverlayConfig = {
    hasBackdrop: true,
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'bottom',
        highlightElement: 'download-button',
        cardText: [
            'It\'s that quick to generate and download a report!',
        ],
        cardAction: 'Click anywhere to continue',
        sourcePath: 'assets/bodymovin/kate_bottom_right.json',
        backdropClickSkip: false,
    },
};

export const firstDownloadReportMedal: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'medal',
        positionX: 'center',
        positionY: 'center',
        sourcePath: 'assets/bodymovin/medal_first_report.json',
        backdropClickSkip: false,
    },
};

export const startNewProject: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'global-onboarding__backdrop',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'top',
        highlightElement: 'new-project-button',
        cardText: [
            'Don’t forget, start a new project by tapping the <b>+</b> symbol',
        ],
        cardAction: 'Click on the <b>+</b> button to continue',
        sourcePath: 'assets/bodymovin/kate_top_left.json',
        backdropClickSkip: true,
    },
};

export const mainMenu: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'backdrop-transparent',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'top',
        highlightElement: 'titles-table',
        cardText: [
            'In the <b>Main Menu</b> you can open <b>Previous Projects</b>, or re-download any of your <b>Purchased Files</b> you have previously bought.',
        ],
        cardAction: 'Click anywhere to continue...',
        sourcePath: 'assets/bodymovin/kate_top_right.json',
        backdropClickSkip: false,
    },
};

export const purchaseOptions: IOverlayConfig = {
    hasBackdrop: false,
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'bottom',
        cardText: [
            'You can either <b>add new</b> title registers, or <b>update</b> those in your project to today’s date',
        ],
        cardAction: 'Select an option to continue',
        sourcePath: 'assets/bodymovin/kate_bottom_left.json',
        backdropClickSkip: true,
    },
};

export const searchField: IOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'backdrop-transparent',
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'right',
        positionY: 'top',
        cardText: [
            `Here you can search <b>HM Land Registry</b> by <b>title number</b> or <b>owner</b> to find title registers.
       You can paste in multiple titles at a time to search for. Right, let’s search for a title!`,
        ],
        cardAction: 'Click anywhere to continue',
        sourcePath: 'assets/bodymovin/kate_top_right.json',
        backdropClickSkip: false,
    },
};

export const purchaseTraining: IOverlayConfig = {
    hasBackdrop: false,
    panelClass: 'global-onboarding__panel',
    content: {
        type: 'character',
        positionX: 'left',
        positionY: 'bottom',
        cardText: [
            `Tap <b>Add</b> to add this title to your basket, and then <b>Purchase</b> to buy it from HM Land Registry and add it to your project
       (don’t worry, you won’t actually be charged, this is just training!)`,
        ],
        sourcePath: 'assets/bodymovin/kate_bottom_left.json',
        backdropClickSkip: true,
    },
};
