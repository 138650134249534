import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventType, FolderDetailsData, FolderDetailsDialogComponent } from '@shared/components/dialogs/folder-details-dialog/folder-details-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FolderDetails } from '@core/types';
import { filter, map } from 'rxjs/operators';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';

@Injectable()
export class FolderService {

    private alertDialog: null | MatDialogRef<AlertDialogComponent>;

    constructor(
        private readonly dialog: MatDialog,
    ) {
    }

    public getFolderDetails(): Observable<FolderDetails> {
        return this.openFolderDetailsDialog()
            .pipe(
                filter((result) => !!result && result.event === EventType.confirm),
                map((result) => result.data),
            );
    }

    public openFolderDetailsDialog(folderDetails?: FolderDetails): Observable<FolderDetailsData> {
        const dialogRef = this.dialog.open(FolderDetailsDialogComponent, {
            data: folderDetails,
            panelClass: 'folder-details-dialog',
            autoFocus: false,
        });

        return dialogRef.afterClosed();
    }

    public openAlertDialog(errorData?: { title: string; message: string }): void {
        const isDialogOpened = this.alertDialog && !!this.alertDialog.componentInstance;
        if (!isDialogOpened) {
            this.alertDialog = this.dialog.open(AlertDialogComponent, {
                panelClass: 'report-dialog',
                width: '400px',
                data: errorData,
            });
        }
    }
}
