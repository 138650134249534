import { Injectable } from '@angular/core';
import { applyTransaction, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { IPurchasedFile } from 'app/titles/types';
import { ICollectionTableUI, IPagination } from '@core/types';

export type PurchasedFilesState = EntityState<IPurchasedFile, string> & IPagination & {
    ui: ICollectionTableUI;
}

const initialState = {
    loading: true,
    ui: {
        page: {
            pageIndex: 0,
            pageSize: 10,
        },
    },
};

@Injectable()
@StoreConfig({ name: 'purchased-files', idKey: 'documentId', resettable: true })
export class PurchasedFilesStore extends EntityStore<PurchasedFilesState> {
    constructor() {
        super(initialState);
    }

    public updatePurchasedFiles(collection: IPurchasedFile[], pagination: IPagination): void {
        applyTransaction(() => {
            this.set(collection);
            this.update(pagination);
        });
    }
}
