import { createAction, props } from '@datorama/akita-ng-effects';

const ACTION_NAME_TAG = '[Leases Document]';

export const documentAddedToUploading = createAction(
    `${ACTION_NAME_TAG} Document added to uploading`,
    props<{ temporaryFileId: string; fileName: string }>(),
);

export const documentUploadCanceled = createAction(
    `${ACTION_NAME_TAG} Document upload canceled`,
    props<{ temporaryFileId: string }>(),
);

export const documentUploadSuccess = createAction(
    `${ACTION_NAME_TAG} Document upload success`,
    props<{ temporaryFileId: string; documentId: string }>(),
);

export const documentUploadFailed = createAction(
    `${ACTION_NAME_TAG} Document upload failed`,
    props<{ temporaryFileId: string; error: Error }>(),
);

export const documentDeleted = createAction(
    `${ACTION_NAME_TAG} Document was deleted`,
    props<{ documentId: string }>(),
);

export const documentStateReset = createAction(`${ACTION_NAME_TAG} Document state reset`);
