<div
    *ngIf="!failedLogin"
    class="auth-page__form-title"
>
    <h2>Login to Avail</h2>
</div>

<div *ngIf="isSso; then ssoAuth else formAuth"></div>

<ng-template #ssoAuth>
    <div *ngIf="isPopupLoginFlow; then buttonWithPopup else redirectionAnimation"></div>

    <ng-template #buttonWithPopup>
        <div class="auth-page__sso-auth">
            <p>
                Please click below to log in to your Avail account, no username or password
                required as long as you’re on your internal network
            </p>
            <button
                class="avl-btn avl-btn--blue avl-btn--wide"
                tabindex="1"
                (click)="openSsoModal()"
            >
                LOG IN
            </button>
        </div>
    </ng-template>

    <ng-template #redirectionAnimation>
        <div class="auth-page__sso-auth">
            <avl-spinner [diameter]="36"></avl-spinner>
            <p class="redirection-text">Redirecting to the Single Sign-On provider...</p>
        </div>
    </ng-template>
</ng-template>

<ng-template #formAuth>
    <form
        *ngIf="!failedLogin"
        class="auth-page__form"
        [formGroup]="loginForm"
        (ngSubmit)="login()"
    >
        <div class="form-field">
            <label>Email Address</label>
            <input
                [ngClass]="{'error': emailControl?.touched && emailControl?.invalid}"
                tabindex="1"
                name="username"
                value=""
                type="text"
                placeholder="example@avail.com"
                autofocus
                autocomplete="off"
                formControlName="email"
            />
            <mat-icon [svgIcon]="emailControl?.touched && emailControl?.invalid ? 'error' : 'envelope'"></mat-icon>
            <mat-error *ngIf="emailControl?.touched && emailControl?.invalid">{{ getEmailErrorMessage() }}</mat-error>
        </div>
        <div class="form-field">
            <label>Password</label>
            <input
                [ngClass]="{'error': passwordControl?.touched && passwordControl?.invalid}"
                tabindex="2"
                name="password"
                [type]="isPasswordVisible ? 'text' : 'password'"
                placeholder="Password"
                autocomplete="off"
                formControlName="password"
            />
            <mat-icon
                class="button"
                [svgIcon]="isPasswordVisible ? 'eye-slash' : 'eye'"
                (click)="isPasswordVisible = !isPasswordVisible"
            ></mat-icon>
            <mat-error *ngIf="passwordControl?.touched && passwordControl?.invalid">A password is required</mat-error>
        </div>
        <button
            class="avl-btn avl-btn--blue avl-btn--wide"
            tabindex="3"
            name="login"
            value="Log In"
            type="submit"
            [disabled]="loginForm.invalid"
        >
            LOG IN
        </button>
        <div class="form-field row">
            <a
                tabindex="4"
                [routerLink]="['/reset-password-req']"
            >
                Forgot Password?
            </a>
        </div>
    </form>
</ng-template>

<avl-error-form
    *ngIf="failedLogin"
    [message]="loginError"
    (tryAgain)="resetErrors()"
></avl-error-form>
